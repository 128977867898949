:root {
	--white: #fff;
	--black: #000;
	--gray: #1A1A1A;
	--blue-100: #117DED;
	--blue-200: #192D48;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html, body, #root {
	height: 100%;
}

body {
	background: var(--black);
	font-size: 10px;
	font-family: sans-serif;
	color: var(--white);
}