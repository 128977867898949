.song {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 1em;
	padding: .5em 1em;
}

.container {
	width: 6em;
	padding-top: 100%;
	position: relative;
	grid-area: 1 / 1 / 3 / 2;
}

.image {
	border-radius: .5em;
	height: 100%;
	width: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
}

.title {
	grid-area: 1 / 2 / 2 / 3;
	align-self: end;
	font-size: 1.3em;
}

.author {
	grid-area: 2 / 2 / 3 / 3;
	font-size: 1.2em;
}

.active {
	grid-area: 3 / 1 / 4 / 2;
	background: var(--gray);
}

.wrapper {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 1em;
	padding: .5em 1em;
}

.progress {
	height: .1em;
	width: 100%;
	background: var(--blue-200);
	display: block;
}

.progress::-webkit-progress-bar {
	background: none;
}

.progress::-webkit-progress-value {
	background: var(--blue-100);
}