.form, .input {
	padding: 1em;
}

.form {
	background: var(--black);
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: 1fr;
	grid-column-gap: .5em;
	grid-area: 1 / 1 / 2 / 2;
}

.input, .button {
	border: 0;
	border-radius: .3em;
}

.input {
	background: var(--gray);
	color: var(--white);
}

.button {
	width: 6em;
	background: var(--blue-200);
	color: var(--blue-100);
	font-weight: bold;
	cursor: pointer;
}